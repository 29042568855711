import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import * as React from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useUnitNamesRedactor from '../../hooks/useUnitNamesRedactor';
import AuthValidation from '../../hoc/AuthValidation';
import { OrderProfileType } from '../../store/responseTypes';
import { ORDER_STATUSES } from '../../constants/orderStatuses';
import momentWithLocale from '../../utils/momentWithLocale/momentWithLocale';
import * as _ from 'lodash';

const getStoreData = () => {
  const storageItem = localStorage.getItem('user'); // return stringify array
  return storageItem ? JSON.parse(storageItem) : [];
};

const TableDataOrderMarkUp = ({ unit, product, quantity, pharmacy, price }) => {
  const unitName = useUnitNamesRedactor(unit.name);
  return (
    <tr>
      <td>
        <NavLink to={`/product/${product.id}`}>{product.name}</NavLink>
      </td>
      <td>{product.id}</td>
      <td>{quantity}</td>
      <td>{unitName}</td>
      <td>{pharmacy.name}</td>
      <td>{price} грн</td>
      <td>{Number(price) * quantity} грн</td>
    </tr>
  );
};

export default () => {
  const { t } = useTranslation();

  const orders: OrderProfileType[] = useSelector((state: RootState) => state.global.userOrders);
  const [seeOrderDetails, setSeeOrderDetails] = useState<OrderProfileType | null>(null);
  const userData = getStoreData();

  const renderTableData = () =>
    _.orderBy(orders, ['created'], ['desc']).map((order: OrderProfileType) => {
      const quantity = order.products.reduce(
        (acc: number, product) => (acc += +product.quantity),
        0
      );
      return (
        <tr key={order.id} onClick={() => setSeeOrderDetails(order)} style={{ cursor: 'pointer' }}>
          <td>{order.id}</td>
          <td>{userData.name}</td>
          <td>{quantity}</td>
          <td>{momentWithLocale(order.created)}</td>
          <td>{t(`orderStatuses.${ORDER_STATUSES[order.state]}`)}</td>
          <td>{order.price}</td>
        </tr>
      );
    });

  const renderTableDataOrder = (order) =>
    order.products.map((product) => <TableDataOrderMarkUp key={product.id} {...product} />);

  return (
    <AuthValidation>
      <h2 className="thinTitle">{t('page_profile_title_text1')}</h2>
      {seeOrderDetails ? (
        <>
          <div
            className="goBack"
            style={{ cursor: 'pointer' }}
            onClick={() => setSeeOrderDetails(null)}
          >
            &#10094; {t('page_profile_go_back_text')}
          </div>
          <div className="scrollableTable">
            <table>
              <thead>
                <tr>
                  <th>{t('page_create_order_text2')}</th>
                  <th>{t('page_create_order_text3')}</th>
                  <th>{t('page_create_order_text4')}</th>
                  <th>{t('page_create_order_text5')}</th>
                  <th>{t('page_create_order_text6')}</th>
                  <th>{t('page_create_order_text7')}</th>
                  <th>{t('page_create_order_text8')}</th>
                </tr>
              </thead>
              <tbody>
                {renderTableDataOrder(seeOrderDetails)}
                <tr>
                  <td className="text-right total" colSpan={8}>
                    {/* @ts-ignore */}
                    {t('page_create_order_text9')} <strong>{seeOrderDetails?.price} грн</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="scrollableTable">
          {orders.length ? (
            <table>
              <thead>
                <tr>
                  <th>{t('page_create_order_table_text1')}</th>
                  <th>{t('page_create_order_table_text2')}</th>
                  <th>{t('page_create_order_table_text3')}</th>
                  <th>{t('page_create_order_table_text4')}</th>
                  <th>{t('page_create_order_table_text5')}</th>
                  <th>{t('page_create_order_table_text6')}</th>
                </tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </table>
          ) : (
            <h3 className="text-center" style={{ marginTop: 30 }}>
              {t('page_profile_text1')}
            </h3>
          )}
        </div>
      )}
    </AuthValidation>
  );
};
