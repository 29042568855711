import React from 'react';
import imgPlaceholder from '../../assets/images/product_placeholder.png';
import useUnitNamesRedactor from '../../hooks/useUnitNamesRedactor';
import { NavLink } from 'react-router-dom';
import { AiOutlineMinus } from 'react-icons/ai';
import { CgMathPlus } from 'react-icons/cg';
import { BiTrashAlt } from 'react-icons/bi';
import { ProductAddToBasketType } from '../../store/responseTypes';
import { changeBasketItemCount, removeBasketItem } from '../../store/actions/basket';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface ITableData {
  product: ProductAddToBasketType;
}

const TableMarkup = ({ product }: ITableData) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const unitName = useUnitNamesRedactor(product?.unit?.unit.name);

  const incrementProduct = (product: ProductAddToBasketType) => {
    const userCount = product.count * Number(product.unit?.percentage);
    if (userCount >= Number(product.quantity)) {
      toast.error(t('product_warning_not_enough'));
      return;
    }
    dispatch(
      changeBasketItemCount({
        ...product,
        count: product.count + 1
      })
    );
  };

  const decrementProduct = (product: ProductAddToBasketType) => {
    if (product.count === 1) {
      toast.error(t('basket_warning_min_count'));
      return;
    }
    product.count--;
    dispatch(changeBasketItemCount(product));
  };
  return (
    <>
      <tr>
        <td>
          <NavLink to={`/product/${product.id}`}>
            <img
              src={product.images && product.images.length ? product.images[0].uri : imgPlaceholder}
              alt={product.name}
              title={product.name}
            />
          </NavLink>
        </td>
        <td>
          <NavLink to={`/product/${product.id}`}>{product.name}</NavLink>
        </td>
        <td>{product.id}</td>
        <td>
          <div className="basketItem-calc">
            <span onClick={() => decrementProduct(product)}>
              <AiOutlineMinus size={20} />
            </span>
            <span className="count">{product.count}</span>
            <span onClick={() => incrementProduct(product)}>
              <CgMathPlus size={20} />
            </span>
          </div>
        </td>
        <td className="unit-name">{unitName}</td>
        <td>{product.fullPrice.toFixed(2)} грн</td>
        <td>{(product.fullPrice * product.count).toFixed(2)} грн</td>
        <td className="action-box">
          <div className="basketItem-remove" onClick={() => dispatch(removeBasketItem(product))}>
            <BiTrashAlt size={25} color={'#888'} />
          </div>
        </td>
      </tr>
    </>
  );
};

export default TableMarkup;
