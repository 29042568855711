import React, { useContext, useEffect, useState } from 'react';
import { Category } from '../../store/responseTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import { useHistory } from 'react-router';
import { FooterContext } from '../MainLayout';

interface ICategoriesSidebarProps {
  onCloseMenu?: () => void;
}

export default ({ onCloseMenu }: ICategoriesSidebarProps) => {
  const history = useHistory();

  const { isVisible } = useContext(FooterContext);

  const [scrolled, setScrolled] = useState(false);
  const categories = useSelector((state: RootState) => state.cats.categories);
  const activeCategory = useSelector((state: RootState) => state.cats.activeCategory);
  //   const movedSidebarClasses = ['categories-sidebar'];

  // if (scrolled) {
  // 	movedSidebarClasses.push('scrollable');
  // }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 320) return setScrolled(true);
    return setScrolled(false);
  };

  const openCategory = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    categoryId?: number
  ) => {
    event.preventDefault();
    history.push(`/products/${categoryId}`);
    if (onCloseMenu) onCloseMenu();
  };

  const hasActiveChildCategory = (category: Category) =>
    category.children?.some((item) => item.id === activeCategory?.id);

  const renderCategories = (categories: Array<Category>) =>
    categories.map((category) => (
      <li key={category.id}>
        <a
          onClick={(e) => openCategory(e, category.id)}
          className={
            category.open || hasActiveChildCategory(category)
              ? 'active parentElement'
              : 'parentElement'
          }
          href={category.link}
        >
          {category.name}
        </a>
        {category.open || hasActiveChildCategory(category) ? (
          <ul className="categories-sidebar-children">
            {category.children
              ? category.children.map((child) => (
                  <li key={child.id}>
                    <a
                      onClick={(e) => openCategory(e, child.id)}
                      href={child.link}
                      className={child.id === activeCategory?.id ? 'active' : ''}
                    >
                      - {child.name}
                    </a>
                  </li>
                ))
              : null}
          </ul>
        ) : null}
      </li>
    ));

  return (
    <ul
      className={`categories-sidebar ${!isVisible && scrolled ? 'scrollable' : ''} ${
        isVisible && scrolled ? 'nonscrollable' : ''
      } 
      `}
    >
      {renderCategories(categories)}
    </ul>
  );
};
