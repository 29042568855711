import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchProductById, setUserPharmacy } from '../../store/actions/products';
import { RootState } from '../../store/reducers/rootReducer';
import { addBasketItem } from '../../store/actions/basket';
import { Product, ProductsUnitsResponse } from '../../store/responseTypes';
import ProductOptions from './ProductOptions';
import Spinner from '../../components/Spinner';
import ProductInfo from './ProductInfo';
import { NumberHelper } from '../../helpers/NumberHelper';
import { useTranslation } from 'react-i18next';
import ErrorBoundaryWrapper from '../../components/ErrorBoundary';
import { toast } from 'react-toastify';
import { useAvailablePharmacies } from '../../hooks/useAvailablePharmacies';

export default () => {
  const { t } = useTranslation();

  const { id }: any = useParams();
  const dispatch = useDispatch();

  const [product, setProduct] = useState<Product | undefined>();
  const [units, setUnits] = useState<ProductsUnitsResponse[]>([]);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [selectedUnit, setSelectedUnit] = useState<number>();
  const [error, setError] = useState('');
  const [selectedPharmacy, setSelectedPharmacy] = useState<number | null>(null);
  const selectedUnitPercentage = units.find((u) => u?.unitId === selectedUnit);
  const [selectedPharmacyIds, setSelectedPharmacyIds] = useState<Array<number>>([]);

  const storeSelectedPharmacyIds = useSelector((state: RootState) => state.products.userPharmacies);
  const basketProducts = useSelector((state: RootState) => state.products.basketProducts);

  const { pharmacies, selectedPharmacies } = useAvailablePharmacies(
    product?.pharmacies || [],
    selectedPharmacyIds
  );

  const defaultProps = {
    pharmacy: null,
    price: '0',
    quantity: '0'
  };

  const {
    pharmacy: productPharmacy,
    price: productPrice,
    quantity: productQuantity
  } = product?.pharmacies?.find((item) => item.pharmacyId === selectedPharmacy) || defaultProps;

  const isValid = selectedUnitPercentage && selectedPharmacy && selectedUnit;
  useEffect(() => {
    setSelectedPharmacyIds(storeSelectedPharmacyIds);
  }, [storeSelectedPharmacyIds]);

  useEffect(() => {
    if (isValid) setError('');
    setSelectedQuantity(1);
  }, [selectedUnit, selectedPharmacy, isValid]);

  useEffect(() => {
    if (product && product.units && product.units.length) {
      setUnits(product.units);
      setSelectedUnit(product.units[0].unitId);
    }
  }, [product]);

  useEffect(() => {
    const getProduct = async () => {
      // @ts-ignore
      const product: Product = await dispatch(fetchProductById(id));
      setProduct(product);
      setSelectedQuantity(1);
    };
    getProduct();
  }, [dispatch, id]);

  const changePharmacyHandler = (id: number) => {
    setSelectedPharmacy(id);
  };

  const showPriceByUnit = (price: string): string => {
    if (!selectedUnitPercentage) return price;
    return (Number(price) * Number(selectedUnitPercentage.percentage)).toFixed(2);
  };

  const handlePrice = (price): string => {
    if (productPrice && productPrice !== '0') return showPriceByUnit(productPrice);
    if (!price) return '0';
    if (price.min === price.max) return price.min;
    return `${Number(price.min)} - ${Number(price.max)}`;
  };

  const loading = useSelector((state: RootState) => state.products.loading);

  const checkQuantity = (editedQuantity = selectedQuantity) => {
    const percentage = Number(selectedUnitPercentage?.percentage) || 1;
    const userSelectQuantity = NumberHelper.round(editedQuantity * percentage, 1000);
    return Number(userSelectQuantity) <= Number(productQuantity);
  };

  const handleUserPharmacy = () => {
    if (!selectedPharmacy) return;

    let result: Array<number> = [];

    const productAdded = basketProducts.find(({ id }) => product?.id === id);

    if (productAdded) {
      result = [
        ...selectedPharmacyIds.filter((id) => id !== productAdded.pharmacy.id),
        selectedPharmacy
      ];
    } else if (selectedPharmacyIds.includes(selectedPharmacy)) {
      result = selectedPharmacyIds;
    } else {
      result = [...selectedPharmacyIds, selectedPharmacy];
    }

    setSelectedPharmacyIds(result);
    dispatch(setUserPharmacy(result));
  };

  const addProductToBasket = () => {
    if (loading) return;

    if (!product || !productPharmacy || !isValid) {
      setError(t('product_warning_choose_variants'));
      toast.error(t('product_warning_choose_variants'));
      return;
    }
    if (!checkQuantity()) {
      setError(t('product_warning_not_enough'));
      toast.error(t('product_warning_not_enough'));
      return;
    }

    handleUserPharmacy();

    dispatch(
      addBasketItem({
        id: product.id,
        quantity: Number(productQuantity) || 0,
        pharmacy: productPharmacy,
        images: product.images,
        manufacturer: product.manufacturer || '',
        name: product.name,
        count: selectedQuantity,
        unit: selectedUnitPercentage,
        fullPrice: Number(handlePrice(productPrice))
      })
    );
    setSelectedQuantity(1);
  };

  const changeQuantityHandler = (action) => {
    const editedQuantity = action === '-' ? selectedQuantity - 1 : selectedQuantity + 1;
    if (!isValid) {
      setError(t('product_warning_choose_variants'));
      toast.error(t('product_warning_choose_variants'));
      return;
    }
    if (selectedQuantity === 1 && action === '-') {
      setError(t('product_warning_less_one'));
      toast.error(t('product_warning_less_one'));
      return;
    }
    if (!checkQuantity(editedQuantity) && action === '+') {
      setError(t('product_warning_max_count'));
      toast.error(t('product_warning_max_count'));
      return;
    }
    setError('');
    if (action === '+') setSelectedQuantity((prevState) => prevState + 1);
    if (action === '-') setSelectedQuantity((prevState) => prevState - 1);
  };

  return (
    <div className="product-page">
      {!product ? (
        <div className="centeredBlock">
          <Spinner />
        </div>
      ) : (
        <>
          <ErrorBoundaryWrapper>
            <ProductInfo
              product={product}
              price={handlePrice(product.price)}
              changeQuantityHandler={changeQuantityHandler}
              selectedQuantity={selectedQuantity}
              loading={loading}
              addProductToBasket={addProductToBasket}
              error={error}
            />
          </ErrorBoundaryWrapper>
          {handlePrice(product.price) !== '0' && (
            <ErrorBoundaryWrapper>
              <ProductOptions
                product={product}
                units={units}
                pharmacies={pharmacies}
                emptyUnits={!units.length}
                selectedPharmacies={selectedPharmacies}
                selectedPharmacy={selectedPharmacy}
                selectedUnit={selectedUnit}
                setSelectedPharmacy={(id) => changePharmacyHandler(id)}
                setSelectedUnit={(value) => setSelectedUnit(value)}
              />
            </ErrorBoundaryWrapper>
          )}
        </>
      )}
    </div>
  );
};
